<template>
    <div class="db-container dashboard-plan">
        <div class="db-modal buy-plan-modal">
            <div :class="`content ${this.buyModalStep == 0 && 'small'}`">
                <div v-if="this.buyModalStep == 0" class="wrapper">
                    <h3>購買方案</h3>
                    <div class="plan-detail">
                        <h4 class="title">方案名稱方案名稱<span>NTD 3,000 / 月</span></h4>
                        <InputUnit title="購買期間" type="select" :selectOptions="buyPeriodOptions" v-model="buyingDetail.period" />
                        <h4 class="total">總價<span><span>NTD</span>18,000</span></h4>
                    </div>
                    <p class="notice">若您確認您要購買此方案，請點擊建立訂單，並依照資訊引導完成付款。</p>
                </div>
                <div v-if="this.buyModalStep == 1" class="wrapper pay-info">
                    <h3>付款資訊</h3>
                    <div class="plan-detail">
                        <div class="total-container">
                            <h4 class="total">總價<span><span>NTD</span>18,000</span></h4>
                        </div>
                        <div class="use-cash">
                            <h4>使用台幣轉帳付款</h4>
                            <p>請將款項轉至下列指定帳戶</p>
                            <p>戶名：柏康工作室<br>銀行代碼：822(中國信託)<br>帳號：451540206340</p>
                        </div>
                        <div class="use-digital-currency">
                            <h4>使用數位貨幣付款</h4>
                            <p>請將款項轉至下列指定帳戶</p>
                            <p>戶名：柏康工作室<br>銀行代碼：822(中國信託)<br>帳號：451640214520</p>
                        </div>
                    </div>
                    <p class="notice">在您完成付款後，請您點選『我已付款』，我們將儘速為您審核款項。</p>
                </div>
                <div v-if="this.buyModalStep == 0" class="btn-container">
                    <button class="blue close-modal">取消</button>
                    <button class="light" @click="nextStep(1)">建立訂單</button>
                </div>
                <div v-if="this.buyModalStep == 1" class="btn-container">
                    <button class="blue close-modal">關閉</button>
                    <button class="light" @click="nextStep(2)">我已付款</button>
                </div>
            </div>
        </div>
        <div class="db-content">
            <h2 class="title">訂閱方案</h2>
            <div class="my-plan">
                <h5>我的方案</h5>
                <div class="plan-block">
                    <h4 class="title">方案名稱<span>等待付款</span></h4>
                    <p class="price">NTD 3,000 / 月</p>
                    <p class="duration">2022/01/02 ~ 2022/07/02</p>
                    <div class="action">
                        <button class="blue">檢視付款資訊</button>
                        <button class="light">我已付款</button>
                    </div>
                </div>
            </div>
            <div class="other-sep"><span>其他方案</span></div>
            <div class="other-plans">
                <div v-for="i in 3" :key="i" class="plan-block">
                    <h4 class="title">方案名稱</h4>
                    <ul class="description">
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>consectetur adipiscing elit</li>
                        <li>sed do eiusmod tempor incididunt ut labore</li>
                        <li>quis nostrud exercitation ullamco</li>
                    </ul>
                    <h5 class="price">NTD 3,000 / 月</h5>
                    <div class="action">
                        <button class="light" @click="openBuyPlanModal()">購買此方案</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
// import RobotsListsChart from '@/components/RobotsListsChart';
import InputUnit from '@/components/InputUnit';
// import MarketLineChart from '@/components/MarketLineChart';
// import {apiNewsDetail} from '@/apis/news.js';

export default {
    name: 'Plan',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
            buyModalStep: 0,
            buyPeriodOptions: [{
                title: "一個月",
                value: "1month"
            }, {
                title: "三個月",
                value: "3months"
            },{
                title: "六個月",
                value: "6months"
            },{
                title: "一年",
                value: "1year"
            }],
            buyingDetail: {
                plan: "",
                period: "1month"
            }
        }
    },
    components: {
        InputUnit,
    },
    methods: {
        openBuyPlanModal(step = 0){
            this.buyModalStep = step
            $('.buy-plan-modal').fadeIn(300).css('display', 'flex')
        },
        nextStep(step = 0){
            switch (step) {
                case 0:
                    //
                    break;
                default:
                    this.buyModalStep = step
                    break;
            }
        }
    },
    mounted() {
        $('body').on('click', '.db-modal:not(.confirm-dialog):not(.robots-setting)', function(){
            $(this).fadeOut(300);
        })
        $('body').on('click', '.close-modal', function(){
            $('.api-setting-modal').fadeOut(300);
        })
        $('body').on('click', '.db-modal > .content', function(e){
            e.stopPropagation();
        })
    },
    computed: {
    },
}
</script>